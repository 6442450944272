<script setup lang="ts">
import { useProductPrice } from '@shopware-pwa/composables-next';
import type { Product } from '@shopware-pwa/types';

const props = defineProps<{
  product: Product;
  isOverview?: boolean;
}>();
const { product } = toRefs(props);

const { unitPrice, price, tierPrices, isListPrice } = useProductPrice(product);
const { getFormattedPrice } = usePrice();
</script>

<template>
  <div>
    <div
      v-if="tierPrices.length <= 1"
      :class="{
        'flex flex-row-reverse flex-wrap gap-2': isOverview || isListPrice,
      }"
    >
      <SharedPrice
        v-if="isListPrice"
        class="text-1xl basis-2/6 justify-end text-gray-900 line-through"
        :value="price?.listPrice?.price"
        :class="{
          'text-[22px] sm:text-2xl lg:text-[24px]': !isOverview,
        }"
      />
      <SharedPrice
        v-if="unitPrice"
        class="basis-2/6 justify-end"
        :class="{
          'text-[22px] sm:text-2xl lg:text-[24px]': !isOverview,
          'text-red': isListPrice,
          'text-gray-900': !isListPrice,
        }"
        :value="unitPrice"
      />
    </div>
    <div v-else>
      <table class="mb-8 w-full table-auto border-collapse text-sm">
        <thead>
          <tr>
            <th
              class="border-b p-4 pb-3 pl-8 pt-0 text-left font-medium text-slate-600 dark:border-slate-600 dark:text-slate-200"
            >
              {{ $t('product.amount') }}
            </th>

            <th
              class="border-b p-4 pb-3 pr-8 pt-0 text-left font-medium text-slate-600 dark:border-slate-600 dark:text-slate-200"
            >
              {{ $t('product.price.label') }}
            </th>
          </tr>
        </thead>
        <tbody class="bg-white dark:bg-slate-800">
          <tr v-for="(tierPrice, index) in tierPrices" :key="tierPrice.label">
            <td
              class="border-b border-slate-100 p-4 pl-8 font-medium text-slate-500 dark:border-slate-700 dark:text-slate-400"
            >
              <span v-if="index < tierPrices.length - 1">
                {{ $t('product.price.to') }}</span
              ><span v-else> {{ $t('product.price.from') }}</span>
              {{ tierPrice.quantity }}
            </td>
            <td
              class="text-current-500 border-b border-slate-100 p-4 pr-8 font-medium dark:border-slate-700 dark:text-slate-400"
            >
              {{ getFormattedPrice(tierPrice.unitPrice) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
